<template>
  <div class="pt-35 slider-product-blocks">
    <b-container>
      <div class="slider-product-blocks-wraper">
        <div class="slider-banken">
          <MiddleBankenComponent />
        </div>
        <div class="home-common-slider slider-inner-box speciaal-voor-block">
          <h5>{{ $t("selected_for_you") }}</h5>
          <div class="carousel-boxWrap">
            <CarouselComponent
              v-if="specialProducts.length"
              v-bind:productData="specialProducts"
              v-bind:settings="CAROUSEL_SETTING_FIRST"
            />
          </div>
        </div>
        <div class="mobile-levering-section show-mobile">
          <InformativeLine class="mobile-informative" :initialSlideMobile="2" />
        </div>
        <div class="rating-card-block">
          <h4 class="card-title">{{ $t("our_certainties") }}</h4>
          <div class="ratingbox-wrap">
            <div
              class="img-rating-box"
              @click="gotoRating()"
              style="cursor: pointer"
            >
              <div class="default-widget-rating-number">
                <span
                  class="rating"
                  v-if="
                    kiyohRatings && kiyohRatings.last_12_month_average_rating
                  "
                >
                  {{
                    kiyohRatings.last_12_month_average_rating
                      .toFixed(1)
                      .replace(".", ",")
                  }}
                </span>
                <span class="rating" v-else> 0,0 </span>
              </div>
              <div class="star-bottom">
                <div class="rating-star-icon">
                  <span class="rating-wrap">
                    <div class="rating-star-wrap">
                      <span
                        class="stars-active"
                        :style="`width: ${
                          (kiyohRatings.last_12_month_average_rating.toFixed(
                            1
                          ) /
                            10) *
                          100
                        }%`"
                      >
                        <b-icon icon="star-fill"></b-icon>
                        <b-icon icon="star-fill"></b-icon>
                        <b-icon icon="star-fill"></b-icon>
                        <b-icon icon="star-fill"></b-icon>
                        <b-icon icon="star-fill"></b-icon>
                      </span>
                      <span class="stars-inactive">
                        <b-icon icon="star"></b-icon>
                        <b-icon icon="star"></b-icon>
                        <b-icon icon="star"></b-icon>
                        <b-icon icon="star"></b-icon>
                        <b-icon icon="star"></b-icon>
                      </span>
                    </div>
                  </span>
                </div>
                <div class="review-count text-center">
                  <span
                    class="text-10 mr-1"
                    v-if="kiyohRatings && kiyohRatings.number_reviews"
                  >
                    {{ kiyohRatings.number_reviews }}
                  </span>
                  <span class="text-10" v-else> 0 </span>
                  <a class="text-10"> {{ $t("review") }}</a>
                </div>
                <div class="review-author text-center">Kiyoh</div>
              </div>
            </div>
            <cmsBlockSimple
              v-if="typeof OurCertainties == 'object'"
              :identifier="OurCertainties.identifier"
            >
            </cmsBlockSimple>
          </div>
        </div>

        <div class="home-common-slider slider-inner-box bestsellers-block">
          <InformativeLine class="mobile-informative" :initialSlideMobile="3" />
          <h5>{{ $t("bestsellers_for_you") }}</h5>
          <div class="carousel-box">
            <CarouselComponent
              v-if="bestSellerProducts.length"
              v-bind:productData="bestSellerProducts"
              v-bind:settings="CAROUSEL_SETTING_FIRST"
            />
          </div>
          <InformativeLine class="mobile-informative" :initialSlideMobile="4" />
        </div>
        <b-breadcrumb class="bars-lr bredcrumb-mobile">
          <b-breadcrumb-item href="#">
            <div class="top-right-txt">
              <span class="top-right-txt-bg"
                ><span class="span-txt">{{ $t("showroom_netherland") }}</span>
              </span>
            </div>
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </b-container>
  </div>
</template>
<script>
import MiddleBankenComponent from "@/esf_utrecht_bankxl/core/components/home/MiddleBankenComponent";
import CarouselComponent from "@/esf_utrecht_bankxl/core/components/core/CarouselComponent";
import { CAROUSEL_SETTING_FIRST } from "@/esf_utrecht_bankxl/assets/constant.js";
import InformativeLine from "@/esf_utrecht_bankxl/core/components/core/InformativeLine";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";

export default {
  name: "MiddleComponent",
  components: {
    CarouselComponent,
    MiddleBankenComponent,
    InformativeLine,
    cmsBlockSimple,
  },
  computed: {
    specialProducts() {
      return this.$store.getters["home/getSpecialSelectedProducts"];
    },
    bestSellerProducts() {
      return this.$store.getters["home/getHomeBestSellerProducts"];
    },
    OurCertainties() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "our_certainties"
      );
    },
    kiyohRatings() {
      return this.$store.getters["home/getKiyohRatings"];
    },
    rateStars() {
      let starCount = 0;
      if (this.kiyohRatings) {
        const averageRating = this.kiyohRatings.last_12_month_average_rating;
        starCount = averageRating / 2;
      }
      return new Array(5).fill(false).map((_, i) => i < parseInt(starCount));
    },
  },
  methods: {
    gotoRating() {
      window.open("https://www.kiyoh.com/reviews/1041467/homingxl");
    },
  },
  data: function () {
    return {
      CAROUSEL_SETTING_FIRST,
    };
  },
};
</script>
