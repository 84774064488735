<template>
  <!-- <cmsBlockSimple
    v-if="typeof home_trend_block_1 == 'object'"
    :identifier="home_trend_block_1.identifier"
  /> -->
  <div>
    <ClientOnly>
      <VueSlickCarousel
        :arrows="false"
        :dots="false"
        :slidesToShow="1"
        :autoplay="true"
        :center="false"
      >
        <div
          class="cms-block-content"
          v-for="(slide, index) in slider.slides"
          :key="index"
        >
          <b-link :to="slide.link" class="">
            <div>
              <div
                class="trendbox-inner"
                :style="getSlideImage(slide.media.url)"
              >
                <div class="img-content">
                  <div class="img-content-inner">
                    <div class="contacts-block">
                      <h4 v-html="slide.title"></h4>
                      <p>
                        <a
                          class="btn trend-btn"
                          :href="slide.link"
                          v-html="slide.buttonText"
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-link>
        </div>
      </VueSlickCarousel>
    </ClientOnly>
  </div>
</template>
<script>
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "TrendComponentImg",
  components: {
    ClientOnly,
    VueSlickCarousel,
  },
  props: ["slider"],
  created() {},
  computed: {},
  methods: {
    getSlideImage(image) {
      return "background-image: url('" + image + "');";
    },
  },
  data: () => {
    return {
      bgImage: "",
    };
  },
};
</script>
