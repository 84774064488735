<template>
  <div class="home-page">
    <HomeTopComponent />
    <MiddleComponent />
    <TrendComponent />
    <HomeCategoryComponent />
    <NewProductsComponent />
    <OurBrands />
  </div>
</template>
<script>
import HomeTopComponent from "@/esf_utrecht_bankxl/core/components/home/HomeTopComponent.vue";
import MiddleComponent from "@/esf_utrecht_bankxl/core/components/home/MiddleComponent.vue";
import TrendComponent from "@/esf_utrecht_bankxl/core/components/home/TrendComponent.vue";
import HomeCategoryComponent from "@/esf_utrecht_bankxl/core/components/home/HomeCategoryComponent.vue";
import NewProductsComponent from "@/esf_utrecht_bankxl/core/components/home/NewProductsComponent.vue";
import OurBrands from "@/esf_utrecht_bankxl/core/components/home/OurBrands.vue";

import config from "@config";
export default {
  name: "HomePage",
  components: {
    HomeTopComponent,
    MiddleComponent,
    TrendComponent,
    HomeCategoryComponent,
    NewProductsComponent,
    OurBrands,
  },
  computed: {
    cmsDefaultPageContent() {
      return this.$store.state.cmsPage.default;
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "test",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "",
        },
      ],
      title: this.$t("home"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
