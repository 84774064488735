var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-category"},[_c('b-container',{},[_c('div',{staticClass:"home-category-wrapper"},[_c('div',{staticClass:"category-box border-top-none"},[_c('div',{staticClass:"category-box-inner"},[_c('div',{staticClass:"carousel-box border-top-none"},[_c('VueSlickCarousel',{attrs:{"arrows":true,"dots":false,"slidesToShow":5,"autoplay":false,"center":false,"responsive":[
                {
                  breakpoint: 1199,
                  settings: {
                    slidesToShow: 5,
                  },
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 540,
                  settings: {
                    slidesToShow: 3,
                  },
                } ]}},_vm._l((_vm.HomeCategorySlider.slides),function(slide,index){return _c('div',{key:index,staticClass:"cms-block-content"},[_c('div',[_c('b-card',{staticClass:"home-category-card"},[_c('router-link',{attrs:{"to":slide.link}},[_c('div',{staticClass:"card-img-top-box"},[_c('b-img',{staticClass:"card-img-top",attrs:{"src":slide.media.url,"alt":"Card image cap"}})],1),_c('div',{staticClass:"card-body-custom"},[_c('div',{domProps:{"innerHTML":_vm._s(slide.description)}})])])],1)],1)])}),0)],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }