<template>
  <div class="trend-right-img">
    <b-container>
      <div class="custom-row-slider custom-row-trend">
        <div class="custom-row-slider-inner custom-row-trend-inner">
          <div class="custom-col-trend-right">
            <div class="trend-right-inner slider-inner-box">
              <h5>{{ $t("our_new_sofas") }}</h5>
              <CarouselComponent
                v-if="ourNewSofas.length"
                v-bind:productData="ourNewSofas"
                v-bind:settings="CAROUSEL_SETTING_SECOND"
              />
            </div>
          </div>
          <div class="custom-col-trend-left">
            <TrendComponentImg :slider="newProductSlider" />
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import CarouselComponent from "@/esf_utrecht_bankxl/core/components/core/CarouselComponent";
import TrendComponentImg from "@/esf_utrecht_bankxl/core/components/home/TrendComponentImg";
import { CAROUSEL_SETTING_SECOND } from "@/esf_utrecht_bankxl/assets/constant.js";

export default {
  name: "NewProductsComponent",
  components: { CarouselComponent, TrendComponentImg },
  data() {
    return {
      CAROUSEL_SETTING_SECOND,
    };
  },
  computed: {
    ourNewSofas() {
      return this.$store.getters["home/getHomeNewSofas"];
    },
    newProductSlider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "trends_slider_nieuw"
      );
    },
  },
};
</script>
