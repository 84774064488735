<template>
  <ul class="hero-list">
    <li
      class=""
      v-for="(blog, index) in home_blogs"
      :index="index"
      :key="index"
    >
      <router-link
        :to="`/blog/` + blog.identifier + '/'"
        class="blog-list-link hero-list-item"
      >
        <b-img :src="blog.featured_image" :alt="blog.title" />
        <p class="item-txt">{{ blog.title }}</p>
        <div class="list-icon-toggle">
          <FontAwesomeIcon icon="chevron-right" />
        </div>
      </router-link>
    </li>
  </ul>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "BloglistComponent",
  props: {
    msg: String,
  },
  computed: {
    home_blogs() {
      return this.$store.getters[`home/getHomeBlogs`];
    },
  },
  data() {
    return {};
  },
  components: { FontAwesomeIcon },
};
</script>
