<template>
  <div>
    <!-- <div class="home-banken-slider">
      <h4>{{ home_banken.title }}</h4>
      <div class="home-banken-content" v-html="home_banken.content"></div>
    </div> -->
    <ClientOnly>
      <VueSlickCarousel
        :arrows="false"
        :dots="false"
        :slidesToShow="1"
        :autoplay="false"
        :center="false"
      >
        <div
          class="cms-block-content home-banken-slider"
          v-for="(slide, index) in homebankenslider.slides"
          :key="index"
        >
          <b-link :to="slide.link" class="">
            <div v-html="slide.description" class="home-banken-content"></div>
          </b-link>
          <b-img :src="slide.media.url" alt="Banken"></b-img>
        </div>
      </VueSlickCarousel>
    </ClientOnly>
  </div>
</template>
<script>
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "MiddleBankenComponent",
  data() {
    return {};
  },
  components: {
    ClientOnly,
    VueSlickCarousel,
  },
  computed: {
    // home_banken() {
    //   return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
    //     "home_banken"
    //   );
    // },
    homebankenslider() {
      const data =
        this.$store.getters["sliders/getSliderByIdentifier"](
          "home_banken_slider"
        );
      return data;
    },
  },
};
</script>
