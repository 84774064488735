<template>
  <div class="trend-left-img">
    <b-container>
      <div class="custom-row-slider custom-row-trend">
        <div class="custom-row-slider-inner custom-row-trend-inner">
          <div class="custom-col-trend-left">
            <TrendComponentImg :slider="trendslider" />
          </div>
          <div class="custom-col-trend-right">
            <div class="trend-right-inner slider-inner-box">
              <h5>{{ $t("latest_trends") }}</h5>
              <CarouselComponent
                v-if="latestTrendsProducts.length"
                v-bind:productData="latestTrendsProducts"
                v-bind:settings="CAROUSEL_SETTING_SECOND"
              />
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import CarouselComponent from "@/esf_utrecht_bankxl/core/components/core/CarouselComponent";
import TrendComponentImg from "@/esf_utrecht_bankxl/core/components/home/TrendComponentImg";
import SELECTED_PRODUCT, {
  CAROUSEL_SETTING_SECOND,
} from "@/esf_utrecht_bankxl/assets/constant.js";

export default {
  name: "TrendComponent",
  components: { CarouselComponent, TrendComponentImg },
  computed: {
    latestTrendsProducts() {
      return this.$store.getters["home/getLatestTrendsProducts"];
    },
    trendslider() {
      const data = this.$store.getters["sliders/getSliderByIdentifier"](
        "trends_slider_trends"
      );
      return data;
    },
  },
  mounted() {},
  data() {
    return {
      SELECTED_PRODUCT,
      CAROUSEL_SETTING_SECOND,
    };
  },
};
</script>
