<template>
  <div class="home-category">
    <b-container class="">
      <div class="home-category-wrapper">
        <div class="category-box border-top-none">
          <div class="category-box-inner">
            <div class="carousel-box border-top-none">
              <VueSlickCarousel
                :arrows="true"
                :dots="false"
                :slidesToShow="5"
                :autoplay="false"
                :center="false"
                :responsive="[
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 5,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 3,
                    },
                  },
                  {
                    breakpoint: 540,
                    settings: {
                      slidesToShow: 3,
                    },
                  },
                ]"
              >
                <div
                  class="cms-block-content"
                  v-for="(slide, index) in HomeCategorySlider.slides"
                  :key="index"
                >
                  <div>
                    <b-card class="home-category-card">
                      <router-link :to="slide.link">
                        <div class="card-img-top-box">
                          <b-img
                            class="card-img-top"
                            :src="slide.media.url"
                            alt="Card image cap"
                          />
                        </div>
                        <div class="card-body-custom">
                          <div v-html="slide.description"></div>
                        </div>
                      </router-link>
                    </b-card>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "HomeCategoryComponent",
  components: { VueSlickCarousel },
  computed: {
    HomeCategorySlider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "home_page_category_slider"
      );
    },
  },
  data() {
    return {};
  },
};
</script>