<template>
  <div class="home-banner-slider" v-if="slider">
    <ClientOnly>
      <VueSlickCarousel
        :arrows="false"
        :dots="false"
        :slidesToShow="1"
        :autoplay="true"
        :speed="1000"
      >
        <b-link
          :to="bSlide.link"
          v-for="(bSlide, index) in slider.slides"
          :key="index"
          class="banner-slide"
        >
          <div
            v-if="bSlide.media.type == 'svg'"
            v-html="bSlide.media.url"
          ></div>
          <b-img v-else :src="bSlide.media.url" :alt="bSlide.title" />
        </b-link>
      </VueSlickCarousel>
    </ClientOnly>
  </div>
</template>
<script>
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "HomeRight",
  components: {
    ClientOnly,
    VueSlickCarousel,
  },
  data() {
    return {
      slide: 0,
      sliding: false,
    };
  },
  computed: {
    slider() {
      let slider = this.$store.getters["sliders/getSliderByIdentifier"]("home");

      if (slider.slides != null) {
        slider.slides.forEach((element) => {
          if (this.mobileCheck == "browser") {
            element.optImage = element.media.url;
          } else if (this.mobileCheck == "tablet") {
            element.optImage = element.media.smaller;
          } else {
            element.optImage = element.media.smallest;
          }
        });
      }

      return slider;
    },
  },
  mounted() {},
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>
